<template>
  <div class="col-md-4">
    <v-form ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="9" sm="9" md="9" xm="9">
                <v-file-input
                  :rules="rules"
                  accept="image/*"
                  v-model="imageItem.file"
                />
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="addLogo">Add Logo</v-btn>
          <v-btn color="blue darken-1" text @click="addAsset">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>


</template>

<script>
  export default {
    data() {
      return {
        formTitle: "Logo Select",
        logoUrl: "",
        imageItem: {
          file: null
        },
        rules: this.$goc.validate.create().required().file(100000),
      }
    },

    methods: {
      addLogo() {
        if (this.validate(this.imageItem.file)) {
          let formData = new FormData();
          formData.append("file", this.imageItem.file);
          this.$goc.request.postMultipart(this.$enums.API.Assets.Single, formData,(response)=>{
            this.logoUrl = response.data.result.url;
            this.successNotify(response.message);
          });
        }
      },
      validate() {
        return !!this.$refs.form.validate();
      },
      addAsset() {
        if (this.validate())
        {
          let requestBody = {
            LogoUrl: this.logoUrl
          };
          this.$goc.request.post(this.$enums.API.Lexicon.AddAsset, requestBody, () => {
              this.successNotify("Logo successfully added");
          });
        }
      },
      successNotify(message) {
        this.$vs.notify({
          title: "Success",
          text: message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success"
        });
      },

      errorNotify(message) {
        this.$vs.notify({
          title: "There was a problem",
          text: message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any();
      },
    },
  }
</script>

<style scoped>

</style>
